import React, { useEffect } from "react";
import Aos from "aos";
import {
  FaProjectDiagram,
  FaUserTie,
  FaUsers,
  FaFileAlt,
} from "react-icons/fa";

import CustomButton from "../../../shared_components/CustomButton";

const WhatMakesUsDiffrent = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="row mx-0 py-5 my-5 px-3 justify-content-center different-section">
      <div className="col-12 col-lg-11 px-0">
        <div className="row mx-0">
          <div className="col-12 px-0 text-center mb-5" data-aos="fade-up">
            <span className="highlight-badge">Our Unique Approach</span>
            <h2 className="section-title text-center mt-3">
              🧠 <span>What Makes Us Different?</span>
            </h2>
          </div>
        </div>

        <div className="row mx-0 gy-4 gap-2 justify-content-center">
          <div className="col-12 col-md-5 px-0" data-aos="fade-up">
            <div className="feature-box">
              <div className="mr-3 icon-wrapper purple">
                <FaProjectDiagram className="feature-icon" />
              </div>
              <div className="content-wrapper">
                <h3>Project-Centric Learning</h3>
                <p>
                  Work on real-world, full-featured projects—not just another
                  to-do app.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-md-5 px-0"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="feature-box">
              <div className="mr-3 icon-wrapper blue">
                <FaUserTie className="feature-icon" />
              </div>
              <div className="content-wrapper">
                <h3>1:1 Mentorship</h3>
                <p>
                  Get support whenever you're stuck. Build confidence as you
                  learn.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-md-5 px-0"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="feature-box">
              <div className="mr-3 icon-wrapper green">
                <FaUsers className="feature-icon" />
              </div>
              <div className="content-wrapper">
                <h3>Like-Minded Community</h3>
                <p>
                  Learn, share, and grow with developers on the same journey.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-md-5 px-0"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="feature-box">
              <div className="mr-3 icon-wrapper orange">
                <FaFileAlt className="feature-icon" />
              </div>
              <div className="content-wrapper">
                <h3>Resume-Ready Projects</h3>
                <p>Build a strong portfolio that impresses recruiters.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mx-0 mt-5">
          <div className="col-12 px-0">
            <div className="cta-box" data-aos="fade-up" data-aos-delay="200">
              <div className="row mx-0 align-items-center">
                <div className="col-12 col-md-8">
                  <h3>🔗 Ready to Move Beyond Tutorials?</h3>
                  <p>
                    Join a program that turns you into a real developer, not
                    just a learner.
                  </p>
                </div>
                <div className="col-12 col-md-4 text-md-end mt-3 mt-md-0">
                  <CustomButton
                    buttonName="Enroll Now"
                    className="fs-18 enroll"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatMakesUsDiffrent;

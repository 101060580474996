import React, { useEffect } from "react";
import Aos from "aos";

const WhyStartedInitiative = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="row mx-0 py-md-5 py-5 justify-content-center why-started-section">
      <div className="col-12 col-md-10 px-0">
        <div className="row mx-0">
          <div className="col-12 text-center">
            <h1 className="text-center mb-3 main-heading" data-aos="fade-up">
              Why We Started
            </h1>
            <p
              className="text-center mb-5 sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Because Tutorials Alone Don't Build Careers
            </p>
          </div>
        </div>

        <div className="row mx-0 g-4 px-3 gap-md-0 gap-2">
          <div className="col-12 col-md-6 px-0 pr-md-4" data-aos="fade-up">
            <div className="p-3 text-md-left text-center feature-box problem-box">
              <span className="emoji-icon" role="img" aria-label="warning">
                🚨
              </span>
              <h2 className="mb-3 box-title">The Problem</h2>
              <h3 className="mb-2 box-subtitle">
                Tutorials teach the how, not the why
              </h3>
              <p className="box-text">
                Most learners rely on YouTube, bootcamps, and online courses—but
                end up creating basic, tutorial-style projects. These don't
                reflect real-world challenges or demonstrate true development
                skills. When it's time to build full-scale apps or ace
                interviews, many find themselves stuck.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 px-0 pl-md-4" data-aos="fade-up">
            <div className="p-3 text-md-left text-center feature-box vision-box">
              <span className="emoji-icon" role="img" aria-label="target">
                🎯
              </span>
              <h2 className="mb-3 box-title">Our Vision</h2>
              <h3 className="mb-2 box-subtitle">
                Turning Beginners into Job-Ready Developers
              </h3>
              <p className="box-text">
                We're here to close the gap between learning and real-world
                application. With hands-on project building, expert mentorship,
                and a vibrant dev community, we help students go beyond the
                basics and build portfolio-worthy projects that stand out.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyStartedInitiative;

import Certificates from "./Certificates";
import EffectiveFee from "./EffectiveFee";
import EnrollSection from "./EnrollSection";
import GetStart from "./GetStart";
import HeroSection from "./HeroSection";
import SuccessStory from "./SuccessStory";
import WhatLearn from "./WhatLearn";
import WhatMakesUsDiffrent from "./WhatMakesUsDiffrent";
import WhoJoin from "./whoJoin";
import WhyJoin from "./WhyJoin";
import WhyStartedInitiative from "./WhyStartedInitiative";

const Bootcamp = () => {
  return (
    <>
      <div className="">
        <HeroSection />
        <WhyStartedInitiative />
        <WhatMakesUsDiffrent />
        <WhatLearn />
        <EnrollSection />
        <WhyJoin />
        <EffectiveFee />
        <WhoJoin />
        <SuccessStory />
        {/* <Certificates /> */}
        <GetStart />
      </div>
    </>
  );
};
export default Bootcamp;
